<template>
  <div class="collab-detail">
    <div class="form-content content">
      <!--   Actions section   -->
      <CRow class="pb-4">
        <CCol col="12" class="d-flex justify-content-end">
          <CDropdown
              inNav
              class="c-header-nav-items custom-dropbox"
              placement="bottom-end"
              add-menu-classes="pt-0"
          >
            <template #toggler class="justify-content-end">
              <CButton
                  :disabled="loading"
                  color="dark"
                  class="zq--custom-button"
                  variant="outline">
                <span>Actions </span>
                <i v-if="theme === 'main'"
                   class="fa fa-angle-down font-weight-bold"></i>
                <i v-else class="fa fa-caret-down"></i>
              </CButton>
            </template>

            <CDropdownItem class="p-0">
              <CButton
                  :disabled="loading"
                  pill
                  color="dark"
                  class="action-custom-button"
                  variant="ghost"
                  @click="deleteModal = true"
              >
                Delete
              </CButton>
            </CDropdownItem>
          </CDropdown>
        </CCol>
      </CRow>
      <!--   Message    -->
      <CRow>
        <CCol col="12">
          <CCard class="zq--wizard-card">
            <CCardHeader>
              <div class="d-flex justify-content-between">
                <strong class="title">Message</strong>
              </div>
            </CCardHeader>
            <CCardBody>
              <CRow class="mb-4">
                <CCol col="12">
                  <strong>{{ formData.subject }}</strong>
                </CCol>
              </CRow>
              <CRow>
                <CCol col="12">
                  {{ formData.body }}
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>

      <!--   DETAIL   -->
      <CRow>
        <CCol col="12">
          <CCard class="zq--wizard-card">
            <CCardHeader @click="basicIsCollapsed = !basicIsCollapsed">
              <div class="d-flex justify-content-between">
                <strong class="title">Overview</strong>
                <CLink class="card-header-action btn-minimize">
                  <ClCardArrow :is-collapsed="basicIsCollapsed" />
                </CLink>
              </div>
            </CCardHeader>

            <CCollapse :show="basicIsCollapsed" :duration="400">
              <CCardBody>
                <!--   ID  -->
                <ClCardRow label="ID" :content="formData.id" />
                <!--Created -->
                <ClCardRow label="Created" :content="dateFormate(formData.added)" />
                <!--         Space Name       -->
                <ClCardRow label="Space Name" :content="formData.spaceName" />
                <!--       Member Group         -->
                <ClCardRow label="Member Group">
                  <template #content>
                    <ul class="zq--list">
                      <li v-for="(group, index) in formData.memberGroup"
                          :key="index" class="group__colored mr-2">{{ group }}
                      </li>
                    </ul>
                  </template>
                </ClCardRow>
                <!--         Members       -->
                <ClCardRow label="Members">
                  <template #content>
                    <ul class="zq--list">
                      <li v-for="(group, index) in formData.members"
                          :key="index" class="group__colored mr-2">{{ group }}
                      </li>
                    </ul>
                  </template>
                </ClCardRow>
                <!--       Status         -->
                <ClCardRow label="Status" :content="formData.status" />
                <!--       Scheduling         -->
                <ClCardRow label="Scheduling" :content="formData.scheduling" />
                <!--         Constraints       -->
                <ClCardRow label="Constraints" :content="formData.constraints" />
                <!--       Translations         -->
                <ClCardRow label="Translations">
                  <template #content>
                    <ul class="zq--list">
                      <li v-for="(group, index) in formData.translations"
                          :key="index" class="group__colored mr-2">{{ group }}
                      </li>
                    </ul>
                  </template>
                </ClCardRow>
                <!--        Translatable Fields        -->
                <ClCardRow label="Translatable Fields">
                  <template #content>
                    <ul class="zq--list">
                      <li v-for="(group, index) in formData.translatableFields"
                          :key="index" class="group__colored mr-2">{{ group }}
                      </li>
                    </ul>
                  </template>
                </ClCardRow>
                <!--        Tags        -->
                <ClCardRow label="Tags">
                  <template #content>
                    <ul class="zq--list">
                      <li v-for="(group, index) in formData.tags"
                          :key="index" class="group__colored mr-2">{{ group }}
                      </li>
                    </ul>
                  </template>
                </ClCardRow>
                <!--        Metadata        -->
                <ClCardRow label="Metadata">
                  <template #content>
                    <ul class="zq--list">
                      <li v-for="(meta, index) in formData.metadata"
                          :key="index">
                        <span>{{ meta.key }} </span>

                      </li>
                    </ul>
                  </template>
                </ClCardRow>
              </CCardBody>
              <!--      Spinner        -->
              <PreviewSpiner
                  v-if="loading"
              />
            </CCollapse>
          </CCard>
        </CCol>
      </CRow>
      <!--    Translations    -->
      <Translations :settingsData="formData" :translationsData="translationsData" />
    </div>
    <!--  Modal  -->
    <Modal
        :modalShow="deleteModal"
        messageGeneral="You are about to delete a Message , Are you sure?"
        title="Delete Message"
        @doFunction="deleteMessage"
        v-on:toggle-modal="deleteModal = false"
    />
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import {pageConfig} from '@/config';
import PreviewSpiner from "@/shared/UI/Spiner";
import routerBreadcrumbs from "@/router/breadcrumb/routerBreadcrumbs";
import Translations from '../../Translation.vue';
import ClCardRow from "@/shared/UI/ClCardRow";
import ClCardArrow from "@/shared/UI/ClCardArrow";
import Modal from "@/shared/UI/Modal";
import {dateFormate} from '@/utils/dateFormate';

export default {
  name: 'previewInbox',
  components: {
    PreviewSpiner,
    Translations,
    ClCardRow,
    Modal,
    ClCardArrow
  },
  data() {
    return {
      formData: {
        id: '',
        memberGroup: [],
        members: [],
        messageType: '',
        subject: '',
        body: '',
        status: '',
        translatableFields: [],
        constraints: '',
        scheduling: '',
        translations: '',
        tags: [],
      },


      basicIsCollapsed: true,
      inboxIsCollapsed: false,
      deleteModal: false,
      itemsPerPage: pageConfig.itemsPerPage,
      query: {},
      sortBy: pageConfig.sortBy,
      page: pageConfig.page,
      translationsData: {
        "German": {"subject": "Herzliche Glückwünsche!", "body": "<p>Dies ist eine Testnachricht</p>"},
        "Spanish": {"subject": "¡Felicidades!", "body": "<p>Este es un mensaje de prueba</p>"},
        "Italian": {"subject": "Congratulazioni!", "body": "<p>Questo è il messaggio di prova</p>"},
        "Russian": {"subject": "Поздравляю!", "body": "<p>Это тестовое сообщение</p>"}
      },
    }
  },
  computed: {
    ...mapGetters('messages', ['success', 'message', 'messages', 'loading', 'singleMessage']),
    ...mapGetters('theme', ['theme'])
  },
  created() {
    this.initialize();
  },
  methods: {
    ...mapActions('messages', ['handleGetMessageById', 'handleDeleteMessages']),

    initialize() {
      this.query = {"id": this.$route.params.id};
      this.handleGetMessageById({
        queryData: this.query,
        sortBy: this.sortBy,
        skip: (this.page - 1) * this.itemsPerPage,
        limit: this.itemsPerPage,
      }).then(data => {
        routerBreadcrumbs(this.$router.currentRoute, {
          name: data.id
        });
      });
    },
    dateFormate,
    editMessage() {
      this.$router.push({
        name: 'Edit Message',
        params: {
          id: this.formData.id,
        }
      });
    },
    deleteMessage() {
      this.deleteModal = false;
      this.handleDeleteMessages({
        id: [this.formData.id],
        queryData: this.query,
        sortBy: this.sortBy,
        skip: (this.page - 1) * this.itemsPerPage,
        limit: this.itemsPerPage,
      })
    }
  },
  watch: {
    singleMessage: {
      deep: true,
      handler: function (val) {
        this.formData = val;
      },
    },
    messages: {
      deep: true,
      handler: function () {
        this.$router.push({path: '/sent'}).catch((e) => {
          console.log(e.message)
        })
      },
    },
  }
}
</script>
<style lang="scss">
.collab-detail {
  height: 100%;
  flex: 2;

  .content {
    border-top: 1px solid var(--zq-content-border-color);
  }

  .form-content {
    height: calc(100% - 50px);
    background: var(--zq-sub-bg);
  }

  .action-custom-button {
    width: 100%;
  }
}

.apiKey-metadata--list {
  padding: 0;
}

.apiKey-metadata--item {
  span {
    font-weight: bolder;
  }
}

.detail-label {
  @media (min-width: 420px) {
    max-width: 220px;
  }
}

.overview-label {
  font-style: italic;
  color: darkgray;
}
</style>
